import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import SEO from '../components/SEO'

const Component = ({ data: { markdownRemark: imprint } }) => (
  <Layout>
    <SEO data={{ title: 'Impressum' }} />
    <div dangerouslySetInnerHTML={{ __html: imprint.html }} />
  </Layout>
)

export const query = graphql`
  query Imprint {
    markdownRemark(frontmatter: { title: { eq: "imprint" } }) {
      id
      html
    }
  }
`

export default Component
